<template>
  <div>
      <p>This is the MY-Documents view.Only MY files here.. replica of the files view. </p>
  </div>
</template>

<script>
export default {
methods: {
  getView (){ this.$http.get('/ViewAccess/mydocuments')
        .then(() => { 
        })
        .catch(() => { 
        })},
},
created(){
  this.getView()
}
}
</script>

<style>

</style>
